import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {OfflineService} from './offline.service';
import {TranslateService} from '@ngx-translate/core';
import {LoadingService} from "./loading.service";

export enum LOGIN_ERRORS {
  INVALID_CREDENTIALS = 'Incorrect username or password',
  INVALID_TOKEN = 'Your session has expired, please login again',
  INVALID_USER_PERMISSIONS = 'You are not authorised to access this application',
  INVALID_USER_GROUP = 'You are not authorised to access this application',
  CONTEXT_ORG_MISMATCH = 'You are not authorised to access this application',
  SSO_NOT_AVAILABLE = 'SSO is not available',
}

declare var Keyboard;
declare var newrelic;
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor (
    public authService: AuthService,
    private offlineService: OfflineService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private translate: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!request.url.includes('api/accounts') && !request.url.includes('api/users') && !request.url.includes('googleapis') && !request.url.includes('event-documents')) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.authService.getToken()}`,
        }
      });
    }
    if (this.offlineService.isOffline) {
      let type;
      if (type = this._checkAllowedOffline(request)) {
        this.offlineService.setOfflineRequest(request, type);
        return throwError({ message: `Offline mode active`, status: 499 });
      } else {
        return throwError({ message: `Offline: please reconnect`, status: 499 });
      }
    } else {
      return next.handle(request).pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (typeof newrelic !== 'undefined') {
              newrelic.noticeError(err);
            }
            if (err.error instanceof ProgressEvent) {
              this.hideKeyboard();
              this.offlineService.isOffline = true;
              let type;
              if (type = this._checkAllowedOffline(request)) {
                this.offlineService.setOfflineRequest(request, type);
                return throwError({
                  message: `Offline mode active`,
                  status: 499,
                });
              } else if (request.url.includes('/organisations')) {
                this.offlineService.isOffline = false;
                return throwError({
                  message: err,
                  status: err.status
                });
              } else if (request.url.includes('documents')) {
                this.offlineService.isOffline = false;
                return throwError({
                  message: 'Error handling document',
                  status: err.status
                });
              } else {
                return throwError({
                  message: `Offline: please reconnect`,
                  status: 499,
                });
              }
            } else if (err.status === 401 && !this.authService.isAuthenticated()) {
              this.hideKeyboard();
              console.error(err.error);
              return throwError(LOGIN_ERRORS[err.error.message.type] || err.error.message);
            } else if (err.status === 401 && this.authService.isAuthenticated()) {
              this.hideKeyboard();
              this.authService.logout();
              this.dialog.closeAll();
              this.loadingService.isLoading = false;
              if (this.router.routerState.snapshot.url.includes('/enrol/')) {
                return throwError('EXPIRED_TOKEN');
              } else {
                this.router.navigate(['/login']);
                console.error(err.error);
                return throwError(LOGIN_ERRORS[err.error.message.type] || 'Your session has expired, please login again');
              }
            } else if (err.status === 403) {
              if (request.url.includes('events-enrol')) {
                return throwError(err);
              } else if (request.url.includes('events-register')) {
                return throwError(err);
              } else if(request.url.includes('login')) {
                return throwError(LOGIN_ERRORS[err.error.message.type] || LOGIN_ERRORS.INVALID_CREDENTIALS);
              } else {
                this.translate.get('snackbarMessages.notAuthorized').subscribe(v => {
                  this.snackBar.open(v, 'OK');
                  return;
                });
              }
            } else if (err.status >= 500) {
              this.hideKeyboard();
              return throwError('Something went wrong please try again.');
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  private hideKeyboard () {
    if (typeof Keyboard !== 'undefined') {
      if (typeof Keyboard.hide === 'function') {
        Keyboard.hide();
      }
    }
  }

  private _checkAllowedOffline(request: HttpRequest<any>): string {
    // check if events-assess and method post for offline assessment
    if (request.url.indexOf('events-assess') >= 0 && request.method === 'POST' && request.url.indexOf('check-rpl') < 0) {
      if ('participantIds' in request.body) {
        return 'events-bulk-assess';
      } else {
        return 'events-assess';
      }
    }

    // if events-register and post for offline registration
    if (request.url.indexOf('events-register') >= 0 && request.method === 'POST') {
      return 'events-register-signin';
    }

    // if events-register and delete for offline member delete
    if (request.url.indexOf('events-register') >= 0 && request.method === 'DELETE') {
      return 'events-register-remove';
    }

    return null;
  }
}
