import { isRecord } from "./object-utils";

export function logSignature(funcName: string, data?: Record<string, unknown>): string;
export function logSignature(className: string, funcName: string, data?: Record<string, unknown>): string;
export function logSignature(
  classNameOrFuncName: string,
  funcNameOrData?: string | Record<string, unknown>,
  data?: Record<string, unknown>,
): string {
  let result = classNameOrFuncName;
  let logData = data;

  if (funcNameOrData) {
    if (typeof funcNameOrData === 'string') {
      result += '.' + funcNameOrData;
    } else if (!data && isRecord(funcNameOrData)) {
      logData = funcNameOrData;
    }
  }

  result += ': ';

  if (logData) {
    const logDataKeys = Object.keys(logData);

    if (logDataKeys.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      logDataKeys.forEach((key) => (result += `${key}[${String(logData![key])}] `));
    }
  }

  return result;
}
