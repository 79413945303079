import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { DynamicFormInput } from '../models/dynamicForm.model';
import { OrganisationConfigModel } from '../models/organisation-config.model';
import { ApiUtilsService } from '../shared/api-utils.service';
import { Observable } from 'rxjs';
import { IFindUserByExternalSystemId } from '../models/accounts.service.model';
import { AccountService } from './account.service';
import { EventFormAssessors, EventFormReviewer } from '../components/admin/event/event-form/event-form-types';
import { logSignature } from '../shared/log-utils';

export const ORG_KEY = 'organisationConfig';

const className = 'OrganisationService';

@Injectable({
  providedIn: 'root'
})

export class OrganisationService {
  public isLoading = false;
  public organisation: OrganisationConfigModel;
  public showOrgSwitchButton: boolean;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private apiUtilsService: ApiUtilsService,
    private accountService: AccountService
  ) {
    this.loadOrgSet();
    this.showOrgSwitchButton = false;
  }

  public getOrganisationConfig(orgId: string): Promise<OrganisationConfigModel> {
    const url = this.apiUtilsService.setApiUrl(environment.api.getOrganisation, [{ key: '{id}', value: orgId }]);

    return this.http.get<OrganisationConfigModel>(url).toPromise();
  }

  public getCurrentOrganisationConfig(): OrganisationConfigModel {
    const localValue = localStorage.getItem(ORG_KEY);
    return localValue === '' ? null : JSON.parse(localValue) as OrganisationConfigModel;
  }

  public getOrganisations() {
    const requestOptions = {
      orgSelector: true,
    };
    return this.http.get(environment.api.getOrganisationList, {
      params: {
        options: JSON.stringify(requestOptions),
      }
    });
  }

  public getOrganisationAppVersion(orgName) {
    return environment.static.organisationAppConfig.find(org => org.name === orgName) ?
      environment.static.organisationAppConfig.find(org => org.name === orgName).currentVersion :
      environment.static.organisationAppConfig.find(org => org.name === 'etrainu').currentVersion;
  }

  public getOrganisationAppLinks(orgName, platform) {
    const orgConfig = environment.static.organisationAppConfig.find(org => org.name === orgName) ?
      environment.static.organisationAppConfig.find(org => org.name === orgName).appStoreLinks :
      environment.static.organisationAppConfig.find(org => org.name === 'etrainu').appStoreLinks;
    return platform === 'ios' ? orgConfig.ios : orgConfig.android;
  }

  public getOrganisationByDomain(domain): Promise<OrganisationConfigModel> {
    return this.http.get<OrganisationConfigModel>(environment.api.getOrganisationByDomain, { params: { domain } }).toPromise();
  }

  public setOrg(organisation: OrganisationConfigModel | null) {
    const signature = logSignature(className, 'setOrg', { orgId: organisation?.orgId });

    if (organisation === null || typeof organisation === 'undefined') {
      console.error(signature + `Expected not-null organisation`);
      // @todo: Come up with a default config or show a human readable error. Without it, the app will fail to start and the user will see a blank white screen.
      return;
    }

    localStorage.setItem(ORG_KEY, JSON.stringify(organisation));
    this.organisation = JSON.parse(localStorage.getItem(ORG_KEY));
    this.setTranslationFile();
    this.setFavicon();
  }

  public clearOrg(): void {
    localStorage.removeItem(ORG_KEY);
  }

  public loadOrgSet(): void {
    if (this.hasOrgSet()) {
      this.organisation = JSON.parse(localStorage.getItem(ORG_KEY));
      this.setFavicon();
    }
  }

  public hasOrgSet(): boolean {
    return localStorage.getItem(ORG_KEY)
      && localStorage.getItem(ORG_KEY).length > 0
      && JSON.parse(localStorage.getItem(ORG_KEY)) !== null;
  }

  public setTranslationFile(): void {

    if (this.organisation !== null && 'undefined' !== typeof this.organisation.languageFile &&
      'undefined' !== typeof this.organisation.languageFile[environment.languageEnvironment]) {
      const [translationFileName] = this.organisation.languageFile[environment.languageEnvironment]
        .split('/')
        .pop()
        .split('.json');
      this.translate.use(translationFileName);
    } else {
      this.translate.use('etrainu');
    }
  }

  public setFavicon() {
    if (this.organisation) {
      this._document.getElementById('appFavicon').setAttribute('href', this.organisation.favicon || 'favicon.ico');
    }
  }

  public getEventNameFormats(): { format: string, default: boolean }[] {
    if (this.organisation.eventConfig.eventAdminForm.eventNameOptions) {
      return [
        {
          format: this.organisation.eventConfig.eventAdminForm.eventNameOptions.defaultFormat,
          default: true
        },
        ...this.organisation.eventConfig.eventAdminForm.eventNameOptions.formats.map((format) => (
          {
            format,
            default: false
          }
        ))
      ]
    } else {
      return [];
    }
  }

  /**
   * Checks if the organisation has account data enabled
   */
  public orgHasAccountDataEnabled(): boolean {
    if (!this.organisation.eventConfig.participantProfileDisplayAttributes) {
      return false;
    }
    const { explicitPartnershipAccounts, explicitPartnershipAccountAncestors } = this.organisation.eventConfig.participantProfileDisplayAttributes;
    return explicitPartnershipAccounts || explicitPartnershipAccountAncestors;
  }

  /**
   * Returns the organisation's enrolment form config options
   * @returns {DynamicFormInput[]} The organisation's enrolment form config options
   */
  public getOrganisationenrolmentFormConfig(): DynamicFormInput[] {
    return this.organisation.eventConfig.enrolmentFormConfig || [];
  }


  /**
 * @description Finds the externalAssessorResolver for any given organisation based on hard-coded orgIds
 *
 * @param selectedAssessor - The selected event form assessor.
 * @returns An Observable of IFindUserByExternalSystemId[] or null.
 *
 * @todo: Consider the viability of finding a way to security insert a resolver into the orgConfig
 */

  public getExternalAssessorResolver(selectedAssessor: EventFormAssessors | EventFormReviewer): Observable<IFindUserByExternalSystemId[]> | null {
    if (!this.organisation) this.loadOrgSet();
    if (!this.organisation) return null;

    switch (this.organisation.orgId) {
      // NOTE: The orgConfig is already public data. This should be removed if possible
      case 'gay76qPXR3rSb5kVpgiMLcaFDFth9c2Z':
        return this.accountService
          .findUsersByExternalSystemId([{ externalSystemId: `surfguard|${selectedAssessor.id}` }])
    }

    return null;
  }
}
