import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

export enum DevicePlatform {
  WEB = 'WEB',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  UNKNOWN = 'UNKNOWN'
}

declare let cordova: any;
declare let device: any;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private platformSubject = new BehaviorSubject<DevicePlatform>(this.getDevicePlatform());
  public platform$ = this.platformSubject.asObservable();

  // A list of platforms that this app has been tested against
  public static knownDevicePlatforms = [
    DevicePlatform.IOS,
    DevicePlatform.ANDROID
  ];

  private getDevicePlatform(): DevicePlatform {
    if (typeof cordova === 'undefined' || typeof device === 'undefined') {
      return DevicePlatform.WEB;
    }

    const currentPlatform = String(device.platform).toUpperCase();
    const matchingDevicePlatform = DeviceService.knownDevicePlatforms.find(platform => currentPlatform === platform);

    return matchingDevicePlatform || DevicePlatform.UNKNOWN;
  }
}
